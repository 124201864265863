<template>
  <div class="leaderboard">
    <div class="leaderboard__banner">
      <img src="@/assets/img/leaderboard-banner.png" alt="leaderboard">
      <img src="@/assets/img/leaderboard-mobile-banner.png" alt="leaderboard">
    </div>

    <div class="leaderboard__table">
      <div class="leaderboard__header">
        <div class="leaderboard__row">
          <div class="leaderboard__col"> {{ $t('leaderboard.quiz-topic') }}</div>
          <div class="leaderboard__col"> {{ $t('leaderboard.time-frame') }}</div>
          <div class="leaderboard__col"> {{ $t('leaderboard.telegram') }}</div>
          <div class="leaderboard__col"> {{ $t('leaderboard.discord') }}</div>
        </div>
      </div>
      <div class="leaderboard__body main-block">
        <div v-if="tableData.length === 0" class="leaderboard__empty">
          {{ $t('leaderboard.no-data') }}
        </div>
        <div v-else class="leaderboard__row" v-for="(item, i) of tableData" :key="i">
          <div class="leaderboard__col"> {{ item[0] }}</div>
          <div class="leaderboard__col"> {{ item[1] }}</div>
          <div class="leaderboard__col"> {{ item[2] }}</div>
          <div class="leaderboard__col"> {{ item[3] }}</div>
        </div>
      </div>
    </div>

    <div class="leaderboard__mobile-table">
      <div class="main-block leaderboard__empty pa-5" v-if="tableData.length === 0">
        {{ $t('leaderboard.no-data') }}
      </div>
      <div
        v-else
        class="leaderboard__mobile-item main-block"
        v-for="(item, i) of tableData"
        :key="i"
      >
        <div class="d-flex justify-space-between">
          <span>{{ $t('leaderboard.quiz-topic') }}</span>
          <span class="leaderboard_bold">{{ item[0] }}</span>
        </div>
        <div class="d-flex justify-space-between">
          <span>{{ $t('leaderboard.time-frame') }}</span>
          <span class="leaderboard_bold">{{ item[1] }}</span>
        </div>
        <div class="d-flex justify-space-between">
          <span>{{ $t('leaderboard.telegram') }}</span>
          <span class="leaderboard_bold">{{ item[2] }}</span>
        </div>
        <div class="d-flex justify-space-between">
          <span>{{ $t('leaderboard.discord') }}</span>
          <span class="leaderboard_bold">{{ item[3] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Papa from 'papaparse';

export default {
  name: 'leaderboard',

  data() {
    return {
      tableData: [],
    };
  },

  methods: {
    async getData() {
      const url = 'https://docs.google.com/spreadsheets/d/1-x3gniiKzvFudrrHpX6g3-n7lesv42kCbM6bj5L5tSg/export?format=csv';
      const response = await axios.get(url);
      const { data } = Papa.parse(response.data);
      data.shift();
      this.tableData = data;
    },
  },

  async mounted() {
    await this.getData();
  },
};
</script>

<style scoped lang="scss">
.leaderboard {
  margin: 0 auto;
  max-width: 113.9rem;

  &__mobile-table {
    display: none;
  }

  &__empty{
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #8c93d6;
    text-align: center;
  }

  &_bold {
    font-weight: bold;
    color: #283287;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.4rem;
    line-height: 150%;
    letter-spacing: 0.02em;
    font-weight: 600;

    &:not(:first-child) {
      margin-top: 4rem;
    }
  }

  &__col {
    &:nth-child(1) {
      width: 18.1rem;
    }

    &:nth-child(2) {
      width: 19rem;
    }

    &:nth-child(3) {
      width: 13rem;
    }
    &:nth-child(4) {
      width: 13rem;
      text-align: right;
    }
  }

  &__table {
    margin-bottom: 8rem;
  }

  &__header {
    padding: 0 4rem 2.4rem;
  }

  &__body {
    font-weight: bold;
    padding: 4rem;
  }

  &__banner {
    margin-top: 4rem;
    margin-bottom: 6.4rem;

    img {
      width: 100%;

      &:last-child {
        display: none;
      }
    }
  }

  body.binance & * {
    color: white;
  }

  body.avalanche & * {
    color: #151515;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 0 1rem;

    &__banner {
      margin-top: 2rem;
      margin-bottom: 5rem;

      img {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
          //max-height: 49rem;
        }
      }
    }

    &__table {
      display: none;
    }

    &__mobile-table {
      display: block;
    }

    &__mobile-item {
      border-radius: 2rem;
      margin-bottom: 1.2rem;
      padding: 1.6rem 1.2rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #343E9A;

      & > div:not(:last-child) {
        margin-bottom: 1.2rem;
      }
    }
  }
}
</style>
